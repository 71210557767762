<template>
  <div>
    <div class="row text-left">
      <div class="col-md-12 col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="w-100 cursor-pointer">
                  <div class="uploadimg w-100 border p-5 text-center">
                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                    <h6 class="text-muted">clique para enviar imagem</h6>
                  </div>
                  <input
                    type="file"
                    @change="onFileChange"
                    name="image"
                    class="d-none"
                  />
                </label>
              </div>
              <div class="col-12 text-center">
                <b-img
                  v-if="form.avatar && form.avatar != '' && form.avatar != null"
                  class="w-100"
                  :src="form.avatar"
                  rounded
                  alt="Rounded image"
                ></b-img>
                <b-img
                  v-else
                  class="w-100"
                  src="@/assets/images/notimg.jpg"
                  rounded
                  alt="Rounded image"
                ></b-img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-8">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-3">Dados Pessoais</h5>
            <hr />
            <div class="row">
              <div class="col-12 col-lg-12 mb-base">
                <label>
                  <span class="mr-1 text-danger">*</span>
                  Nome
                </label>
                <b-form-group>
                  <b-form-input
                    placeholder="Nome completo"
                    for="text"
                    v-model="form.name"
                    :class="{ 'is-invalid': form && $v.form.name.$error }"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 col-lg-6 mb-base">
                <label>Domínio</label>
                <b-form-group>
                  <b-form-input
                    disabled
                    placeholder="Domínio"
                    for="text"
                    v-model="form.dominio"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mb-base">
                <label>E-mail</label>
                <b-form-group>
                  <b-form-input
                    disabled
                    placeholder="E-mail"
                    for="text"
                    v-model="form.email"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mb-base">
                <div class="form-group">
                  <label>Telefone</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.phone"
                    v-mask="'(##) #####-####'"
                    placeholder="Telefone"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mb-base">
                <div class="form-group">
                  <label>CPF</label>
                  <input
                    type="text"
                    class="form-control"
                    v-mask="'###.###.###-##'"
                    v-model="form.cpf"
                    placeholder="CPF"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-3">Endereço</h5>
            <hr />
            <div class="row">
              <div class="col-md-12 col-lg-4">
                <label>CEP</label>
                <b-form-group>
                  <b-form-input
                    placeholder="CEP"
                    for="text"
                    v-mask="'#####-###'"
                    v-model="form.cep"
                    v-on:keyup="buscar"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-4">
                <label>Estado</label>
                <multiselect
                  :options="estados"
                  label="text"
                  v-model="estado"
                  class="helo"
                  placeholder="Estado"
                  select-label="Selecionar"
                  deselect-label="Remover"
                ></multiselect>
              </div>
              <div class="col-md-12 col-lg-4">
                <label>Cidade</label>
                <multiselect
                  :options="cidades"
                  v-model="form.city"
                  class="helo"
                  placeholder="Cidade"
                  select-label="Selecionar"
                  deselect-label="Remover"
                ></multiselect>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Avenida/Rua</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Avenida/Rua"
                    for="text"
                    v-model="form.street"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Número</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Número"
                    for="text"
                    v-model="form.number"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Bairro</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Bairro"
                    for="text"
                    v-model="form.district"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Complemento</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Complemento"
                    for="text"
                    v-model="form.complement"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-3">Senha</h5>
            <hr />
            <div class="row">
              <div class="col-md-12 col-lg-12">
                <label>Senha</label>
                <input
                  type="password"
                  v-model="password"
                  class="form-control"
                  placeholder="Nova Senha"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-right">
                <b-button variant="success" @click.prevent="salvarData()">
                  <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                  Salvar
                </b-button>
                <b-button variant="danger" class="ml-2">
                  <i class="bx bx-x font-size-16 align-middle mr-2"></i>
                  Cancelar
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import vue2Dropzone from 'vue2-dropzone'
import axios from 'axios'
import brasil from '@/assets/brasil.json'
import { required } from 'vuelidate/lib/validators'
export default {
  components: {
    Multiselect,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      password: '',
      form: {
        state: null,
        name: null,
        phone: null,
        cpf: null,
      },
      img: null,
      cidades: [],
      estado: null,
      estados: [
        { value: null, text: 'Selecione um estado' },
        { value: 'AC', text: 'Acre' },
        { value: 'AL', text: 'Alagoas' },
        { value: 'AP', text: 'Amapá' },
        { value: 'AM', text: 'Amazonas' },
        { value: 'BA', text: 'Bahia' },
        { value: 'CE', text: 'Ceará' },
        { value: 'DF', text: 'Distrito Federal' },
        { value: 'ES', text: 'Espírito Santo' },
        { value: 'GO', text: 'Goiás' },
        { value: 'MA', text: 'Maranhão' },
        { value: 'MT', text: 'Mato Grosso' },
        { value: 'MS', text: 'Mato Grosso do Sul' },
        { value: 'MG', text: 'Minas Gerais' },
        { value: 'PA', text: 'Pará' },
        { value: 'PB', text: 'Paraíba' },
        { value: 'PR', text: 'Paraná' },
        { value: 'PE', text: 'Pernambuco' },
        { value: 'PI', text: 'Piauí' },
        { value: 'RJ', text: 'Rio de Janeiro' },
        { value: 'RN', text: 'Rio Grande do Norte' },
        { value: 'RS', text: 'Rio Grande do Sul' },
        { value: 'RO', text: 'Rondônia' },
        { value: 'RR', text: 'Roraima' },
        { value: 'SC', text: 'Santa Catarina' },
        { value: 'SP', text: 'São Paulo' },
        { value: 'SE', text: 'Sergipe' },
        { value: 'TO', text: 'Tocantins' },
      ],
    }
  },
  validations: {
    form: {
      name: { required },
    },
  },
  created() {
    this.form = this.$store.state.auth.user
    this.estado = { value: this.form.state, text: this.form.state }
  },
  watch: {
    estado: function () {
      if (this.estado != '') {
        this.cidades = brasil[this.estado.value].cidades
      }
    },
  },
  methods: {
    ...mapActions('profile', ['ActionProfileUpdate']),
    salvarData() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.password != '') {
          this.form.password = this.password
        }
        this.submitProfile()
      }
    },
    async submitProfile() {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando Dados',
          icon: 'info',
          showConfirmButton: false,
        })

         this.form.state = this.estado.value
          if (this.form.avatar != '' && this.form.avatar != null && this.img == null ) {
            var t = this.form.avatar.split('image/')
            t = t[1].split('/1000')

            this.form.avatar = t[0]
          }
        if (this.img != null) {
          let data = new FormData()

          if (this.img == null && this.avatar != null) {
            data.append('avatar', '')
          }

          data.append('image', this.img)
          data.append('name', this.form.name)
          data.append('cpf', this.form.cpf)
          data.append('phone', this.form.phone)
          data.append('state', this.form.state)
          data.append('city', this.form.city)
          data.append('street', this.form.street)
          data.append('district', this.form.district)
          data.append('number', this.form.number)
          data.append('cep', this.form.cep)
          if (this.password != '') {
            data.append('password', this.form.password)
          }
          await this.ActionProfileUpdate(data)
        } else {
          await this.ActionProfileUpdate(this.form)
        }

        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (e) {
        console.log(e)
        this.$swal({
          title: 'Erro!',
          text: 'Não foi possível realizar a ação!',
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.img = file
      this.form.avatar = URL.createObjectURL(file)
    },
    buscar: function () {
      var self = this
      if (/^[0-9]{5}-[0-9]{3}$/.test(this.form.cep)) {
        axios
          .get('https://viacep.com.br/ws/' + this.form.cep + '/json/')
          .then((response) => {
            this.form.state = response.data.uf
            this.estado = { value: response.data.uf, text: response.data.uf }
            this.form.city = response.data.localidade
            this.form.street = response.data.logradouro
            this.form.district = response.data.bairro
          })
      }
    },
  },
}
</script>
